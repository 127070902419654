@tailwind base;
@tailwind components;
@tailwind utilities;

/* varela-round-regular - latin */
@layer utilities {
  .inslide::-webkit-slider-thumb {
    pointer-events: all;
    width: 24px;
    height: 24px;
    -webkit-appearance: none;
  }

  .dropdown:focus-within .dropdown-menu {
    opacity: 1;
    transform: translate(0) scale(1);
    visibility: visible;
  }


  .content-div:hover .fd-cl {
    opacity: 0.25;
  }

  .content-div:hover .fd-sh {
    opacity: 1;
  }

  .loader-dots div {
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  .loader-dots div:nth-child(1) {
    left: 8px;
    animation: loader-dots1 0.6s infinite;
  }

  .loader-dots div:nth-child(2) {
    left: 8px;
    animation: loader-dots2 0.6s infinite;
  }

  .loader-dots div:nth-child(3) {
    left: 32px;
    animation: loader-dots2 0.6s infinite;
  }

  .loader-dots div:nth-child(4) {
    left: 56px;
    animation: loader-dots3 0.6s infinite;
  }

  @keyframes loader-dots1 {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes loader-dots3 {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(0);
    }
  }

  @keyframes loader-dots2 {
    0% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(24px, 0);
    }
  }

  .text-purple-gradient {
    background: "linear-gradient(180deg, rgba(66, 0, 255, 0) 0%, rgba(66, 0, 255, 0) 0%, #6F00FF 0.01%, #9E00FF 100%, #9E00FF 100%)";
    background-clip: "text";
    color: "transparent";
    -webkit-background-clip: "text";
  }

  .bg-purple-gradient {
    background-image: "linear-gradient(180deg, rgba(66, 0, 255, 0) 0%, rgba(66, 0, 255, 0) 0%, #6F00FF 0.01%, #9E00FF 100%, #9E00FF 100%)";
  }

  .collection-card {
    width: 24em;
    -moz-border-radius: 0.8em;
    -webkit-border-radius: 0.8em;
    border-radius: 0.8em;
  }

  .scroll-table {
    overflow-x: auto;
  }

  .scroll-table tr td:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
  }

  .scroll-table tr th:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
  }

  /* input[type="radio"]+label span {
    transition: background .2s,
      transform .2s;
  }

  input[type="radio"]+label span:hover,
  input[type="radio"]+label:hover span {
    transform: scale(1.2);
  }

  input[type="radio"]:checked+label span {
    background-color: #3490DC;
    box-shadow: 0px 0px 0px 2px white inset;
  }

  input[type="radio"]:checked+label {
    color: #3490DC;
  } */

  .radio+label span {
    transition: background .2s,
      transform .2s;
  }

  .radio+label span:hover,
  .radio+label:hover span {
    transform: scale(1.2);
  }

  .radio:checked+label span {
    /* background-color: #3490DC; */
    background-color: #4ade80;
    box-shadow: 0px 0px 0px 2px white inset;
  }

  .radio:checked+label {
    color: #4ade80;
  }

  /* For Webkit-based browsers (Chrome, Safari and Opera) */
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }

  /* For IE, Edge and Firefox */
  .scrollbar-hide {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .slide-picture {
    -webkit-animation-name: cssAnimation;
    -webkit-animation-duration: 3s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: ease;
    -webkit-animation-fill-mode: forwards;
    transform: scale(0.9);
    transition: transform 300ms;
  }

  @-webkit-keyframes cssAnimation {
    from {
      -webkit-transform: rotate(0deg) scale(1) skew(0deg) translate(100px);
    }

    to {
      -webkit-transform: rotate(0deg) scale(2) skew(0deg) translate(100px);
    }
  }
}